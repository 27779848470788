<template>
  <div class="flex-row aa">
    <div class="flex-l">
      <el-scrollbar>
        <el-tree
          :data="groupData"
          :props="props"
          @node-click="nodeClick"
          ref="treeGroup"
          default-expand-all
          node-key="node_id"
          :default-expanded-keys="['a_0']"
        >
          <template #default="{ node, data }">
            <label class="prefix" v-if="data.nodetype != '3'"
              ><img
                style="width: 18px; height: 18px; margin-top: 3px"
                src="../../assets/meun/jk.png"
                alt=""
            /></label>
            <label
              style="
                padding-left: 5px;
                font-size: 13px;
                color: #000;
                margin-top: 3px;
              "
              >{{ node.label }}</label
            >
          </template>
        </el-tree>
      </el-scrollbar>

      <div class="flex-row mb">
        <el-button type="primary" @click="add_group">{{
          $t("map.tianjiachezu")
        }}</el-button>
        <el-button type="warning" @click="edit_group">{{
          $t("map.xiugaichezu")
        }}</el-button>
        <el-button type="danger" @click="delete_group">{{
          $t("map.shanchuchezu")
        }}</el-button>
      </div>
      <div class="flex-row addss" v-if="group_is_show">
        <div class="cname">{{ $t("map.mingcheng") }}</div>
        <el-input
          v-model="group_row.gName"
          :placeholder="$t('map.mingcheng')"
          clearable
          class="mc"
        ></el-input>
        <el-button type="primary" @click="save_data" class="mc">{{
          $t("map.btnSave")
        }}</el-button>
      </div>
    </div>
    <div class="flex-r">
      <el-transfer
        filterable
        v-model="value_transfer"
        :data="data_transfer"
        style="height: 100%"
        @change="changeGroup"
        :props="props_transfer"
        :titles="[t('commKey.CARGROUPLEFT'), t('commKey.CARGROUPRIGHT')]"
      />
    </div>
  </div>
</template>

<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import { ref, onMounted, getCurrentInstance, reactive } from "vue";
import { useI18n } from "vue-i18n";
import refresh_table from "../../utils/refrshTable";

let { proxy } = getCurrentInstance();
const { t } = useI18n();

const treeGroup = ref("");

const groupData = ref([]);
const props = {
  value: "node_id",
  label: "node_name",
  children: "children",
};
const props_transfer = {
  key: "id",
  label: "vPlate",
};

//////////////////////////////////
// const generateData = () => {
//   const data = [];
//   for (let i = 1; i <= 15; i++) {
//     data.push({
//       key: i,
//       label: `Option ${i}`,
//       disabled: i % 4 === 0,
//     });
//   }
//   return data;
// };

const data_transfer = ref([]);
const value_transfer = ref([]);
// const g_name = ref([]);
const group_row = reactive({ gid: 0, gName: "", cid: 0, pid: 0 });
const group_is_show = ref(false);
let tree_node = null;

const add_group = () => {
  group_is_show.value = true;

  // group_row.value = {};
};
const edit_group = () => {
  let node_data = treeGroup.value.getCurrentNode();
  console.log(node_data);
  if (node_data.node_type == 1) {
    ElMessage({
      message: t("commKey.QXZFZ"),
      type: "error",
    });
  } else {
    group_row.gid = node_data.id;
    group_row.gName = node_data.gName;
    group_row.node_name = node_data.gName;
    group_row.cid = node_data.gCompanyID;
    group_row.pid = node_data.gParentID;
    group_is_show.value = true;
  }
  // if(node_data.)
  // console.log(row);
};

const delete_group = (e) => {
  let node_data = treeGroup.value.getCurrentNode();

  if (node_data.node_type == 1) {
    ElMessage({
      message: t("commKey.QXZFZ"),
      type: "error",
    });
  } else {
    ElMessageBox.confirm(t("commKey.CONFIRMTITLE"), "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    })
      .then(() => {
        proxy.$api
          .sys_vehgroup_config_delete({ gid: node_data.id })
          .then((res) => {
            // MessageBox
            ElMessage({
              message: t("commKey.DELETESUCCESS"),
              type: "success",
            });
            refresh_table("gps_vehicles,gps_devices,gps_sims", 5);
            getList();
          });

        // ElMessage({
        //   type: "success",
        //   message: "Delete completed",
        // });
      })
      .catch(() => {
        // ElMessage({
        //   type: "info",
        //   message: "Delete canceled",
        // });
      });
  }
};

const changeGroup = (e) => {
  // console.log(e);
  if (tree_node.node_type == 2) {
    let vids = [];
    if (e.length > 0) {
      vids = e;
    }
    proxy.$api
      .sys_vehgroup_veh_config_add({ gid: tree_node.id, vids: vids.join(",") })
      .then((res) => {
        // MessageBox
        ElMessage({
          message: t("commKey.MSG_2"),
          type: "success",
        });
      });
  } else {
    ElMessage({
      message: t("commKey.QXZCZ"),
      type: "error",
    });
  }
};
const save_data = (e) => {
  // group_row.gName = group_row.node_name;
  proxy.$api.sys_vehgroup_save_config(group_row).then((res) => {
    // MessageBox
    ElMessage({
      message: t("commKey.MSG_2"),
      type: "success",
    });
    refresh_table("gps_vehicles,gps_devices,gps_sims", 5);
    getList();
    group_row.gid = 0;
    group_row.gName = "";
    group_row.cid = 0;
    group_row.pid = 0;
    group_is_show.value = false;
  });
};
const nodeClick = (row) => {
  console.log(row);
  tree_node = row;

  group_row.gid = 0;
  group_row.gName = "";
  // group_row.node_name = "";
  group_row.cid = 0;
  group_row.pid = 0;
  // group_row.node_name = row.node_name;
  let cid = 0;
  let gid = 0;
  if (parseInt(row.node_type) == 1) {
    //公司信息
    group_row.cid = row.id;
    group_row.pid = 0;
    cid = row.id;
  } else {
    //分组
    group_row.cid = row.gCompanyID;
    group_row.pid = row.id;
    gid = row.id;
    console.log(group_row);
  }

  //获得公司车辆信息
  proxy.$api.sys_vehicles_config_grid({ cid: cid, gid: gid }).then((res) => {
    console.log(res.data);
    data_transfer.value = res.data.data;
    value_transfer.value = res.data.veh_ids;
  });
};
const getList = () => {
  proxy.$api.tree_get_company_tree({}).then((res) => {
    groupData.value = res.data.data;
  });
};

onMounted(() => {
  getList();
});
</script>

<style lang="scss" scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .flex-l {
    width: 330px;
  }
  .cname {
    width: 60px;
    line-height: 24px;
  }
}
.mb {
  padding-top: 10px;
  text-align: right;
  justify-content: flex-end;
  padding-right: 10px;
  border-top: 1px solid #ebeef5;
}
.mr {
  margin-right: 10px;
}
.flex-l {
  border: 1px solid #ebeef5;
  height: 100%;
}
.flex-r {
  margin-left: 50px;
}
.aa {
  padding: 10px 50px;
  height: 100%;
}
::v-deep .el-tree {
  padding: 15px;
}

::v-deep .el-transfer-panel__body {
  border: none;
}
::v-deep .el-transfer-panel {
  height: 100%;
  border: 1px solid #ebeef5;
  width: 330px;
}
::v-deep .el-transfer-panel__header {
  border: none;
  border-bottom: 1px solid #ebeef5;
}
::v-deep .el-button--warning {
  background: #ff960c !important;
}
::v-deep .el-button--danger {
  background: #f9543e !important;
}
::v-deep .el-button {
  height: 30px;
  border-radius: 5px;
}
.addss {
  padding: 10px;
}
::v-deep .el-tree-node__content span {
  font-size: 13px;
  height: 24px;
  line-height: 24px;
  color: #000;
}
::v-deep .el-tree-node__content {
  height: 24px;
  line-height: 24px;
  color: #000;
}
::v-deep .el-tree-virtual-list {
  height: 100% !important;
}
::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label {
  font-size: 13px;
}
::v-deep .el-transfer-panel__body {
  height: calc(100% - 40px);
}
::v-deep .el-transfer-panel__list {
  height: 100% !important;
}
::v-deep .el-tree-node__content span {
  color: #000;
}
::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label {
  color: #000;
}
::v-deep
  .el-transfer-panel
  .el-transfer-panel__header
  .el-checkbox
  .el-checkbox__label {
  color: #000;
}
.mc {
  height: 25px;
}
.flex-r ::v-deep .el-button {
  display: block;
}
.flex-r ::v-deep .el-transfer__button:nth-child(2) {
  margin: 0px;
  margin-top: 10px;
}
.flex-r ::v-deep .el-button + .el-button {
  margin-left: 0px;
}
::v-deep .el-vl__wrapper {
  height: 100% !important;
}
::v-deep .el-virtual-scrollbar {
  height: 100% !important;
}

::v-deep .el-scrollbar {
  height: 90%;
}
::v-deep .el-transfer-panel__filter {
  width: 90%;
}
/* 针对.el-transfer-panel__list的滚动条进行美化 */
::v-deep .el-transfer-panel__list::-webkit-scrollbar {
  width: 5px; /* 滚动条宽度 */
  height: 5px; /* 滚动条高度（通常不需要设置，针对垂直滚动条） */
}

/* 滚动条轨道样式 */
::v-deep .el-transfer-panel__list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.03); /* 轨道背景色 */
  border-radius: 10px; /* 轨道圆角 */
}

/* 滚动条滑块样式 */
::v-deep .el-transfer-panel__list::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.03); /* 轨道背景色 */
  border-radius: 10px; /* 滑块圆角 */
}

/* 可选：悬停时的滑块样式 */
::v-deep .el-transfer-panel__list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2); /* 悬停时颜色变化 */
}
</style>
